//
// Sentry Grid
// Extends Bootstrap 3's grids to allow for:
// - Flexbox-based layouts
// - Center columns vertically

.row {
  &.row-flex {
    display: flex;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: initial;
    }
  }

  &.row-center-vertically {
    display: flex;
    align-items: center;
  }
}
