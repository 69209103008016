// Rubik

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik-regular.woff') format('woff2'),
    url('../fonts/rubik-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik-medium.woff') format('woff2'),
    url('../fonts/rubik-medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

// IBM Plex

@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/ibm-plex-mono-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex';
  src: url('../fonts/ibm-plex-mono-medium.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

// Sentry icon font

@font-face {
  font-family: 'sentry-simple';
  src: url('../fonts/sentry-simple.eot?-iwi56z');
  src: url('../fonts/sentry-simple.eot?#iefix-iwi56z') format('embedded-opentype'),
    url('../fonts/sentry-simple.woff?-iwi56z') format('woff'),
    url('../fonts/sentry-simple.woff2?-lhf5py') format('woff2'),
    url('../fonts/sentry-simple.ttf?-iwi56z') format('truetype'),
    url('../fonts/sentry-simple.svg?-iwi56z#sentry-simple') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
.icon {
  font-family: 'sentry-simple';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-support:before {
  content: '\e910';
}

// Font based icons still in uses in Django rendered views.
.icon-sentry-logo:before {
  content: '\e61a';
}
.icon-sentry-logo-full:before {
  content: '\e623';
}
.icon-exclamation:before {
  content: '\e615';
}
.icon-arrow-left:before {
  content: '\e60e';
}
.icon-arrow-up:before {
  content: '\e610';
}
.icon-arrow-right:before {
  content: '\e611';
}

// Used by plugins in getsentry
.icon-checkmark:before {
  content: '\e60a';
}
.icon-lock:before {
  content: '\e007';
}
