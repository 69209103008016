/**
 * HoverCard
 * ============================================================================
 */

.hovercard {
  @hovercard-padding: 16px;

  .commit-heading {
    margin: 10px 0;
  }

  .commit {
    margin-top: 5px;
    margin-bottom: 0;
    position: relative;
    padding: 1px 0 0 25px;
    font-size: 13px;

    .commit-avatar {
      position: absolute;
      width: 19px;
      height: 19px;
      top: 2px;
      left: 0;

      .avatar {
        width: 19px;
        height: 19px;
      }
    }

    .commit-message {
      line-height: 1.4;
      margin: 2px 0 5px;
    }

    .commit-meta {
      font-size: 12px;
      color: @70;
    }
  }

  .divider {
    position: relative;
    margin: 10px 0;
    padding: 0 10px;

    &:before {
      display: block;
      position: absolute;
      content: '';
      height: 1px;
      top: 50%;
      left: -@hovercard-padding;
      right: -@hovercard-padding;
      background: @trim;
      opacity: 0.6;
      z-index: -1;
    }

    h6 {
      display: inline;
      background: #fff;
      padding: 0 5px;
      margin-left: -15px;
    }
  }

  h6 {
    color: @50 !important;
    font-size: 11px !important;
    margin-bottom: 4px !important;
    text-transform: uppercase;
  }

  p {
    line-height: 1.4;
    margin-bottom: 15px;
  }

  .count-since {
    color: @80;
    font-size: 22px;
    padding: 3px 0 0;
  }

  .deploy-heading {
    margin: 10px 0;
  }

  .deploy {
    margin-top: 5px;
    font-size: 13px;

    .deploy-meta {
      font-size: 12px;
      color: @70;
    }
  }

  .issue-list-body {
    max-height: 300px;
    overflow-y: auto;
  }
}
