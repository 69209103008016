.detailed-error {
  display: flex;
  justify-content: center;
  padding: 18px;
  &.group-event-details-error {
    padding-top: 48px;
  }
}

.detailed-error-icon {
  color: @red;
  font-size: 2.4em;
  padding: 6px 18px 0 18px;
  opacity: 0.3;
}

.detailed-error-content {
  margin-top: 8px;
}

.detailed-error-content-footer {
  margin-top: 18px;
  border-top: 1px solid @trim;
  padding-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailed-error-support-links {
  font-size: 0.9em;
  a {
    margin: 12px;
  }
}

.detailed-error-list {
  li {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
